import React from "react";
import Expand from "./Expand.js";
import data from "../data.json";

class Impressum extends React.Component {
  constructor() {
    super();
    this.state = {
      uhr: false,
      daten: false,
      haftung: false
    };
  }
  handleClickUhr = () => {
    this.setState({ uhr: !this.state.uhr });
  };
  handleClickDaten = () => {
    this.setState({ daten: !this.state.daten });
  };
  handleClickHaftung = () => {
    this.setState({ haftung: !this.state.haftung });
  };
  render() {
    return (
      <>
        <div className="container">
          <div className="container-text">
            <h3>Impressum und Datenschutz</h3>
            <br />

            <p>
              Für die Datenverarbeitung zuständig ist: Karl-Georg Lambert <br />
              In allen Datenschutzangelegenheiten für das Saarland <br /> ist
              zuständiger Ansprechpartner: Karl-Georg Lambert
              <br />
              <br />
              <br />
              Diensteanbieter dieses Internetangebots
              <br />
              im Sinne von Paragraf 5 Telemediengesetz (TMG)
              <br />
              ist Karl-Georg Lambert
              <br />
              <br />
              <br />
              {data.expandabels.map((expandable, i) => (
                <Expand
                  title={expandable.title}
                  html={expandable.text}
                  key={i}
                ></Expand>
              ))}
              <br />
              <br />
              Dipl.-Ing.
              <br />
              Karl-Georg Lambert
              <br />
              <br />
              Ilseplatz 2
              <br />
              66123 Saarbrücken
              <br />
              <br />
              fon 0681 688 97 170
              <br />
              <br />
              <a className="google" href="mailto: info@ibl-baustatik.de">
                info@ibl-baustatik.de
              </a>
              <br />
              <br />
              www.ibl-baustatik.de
            </p>
            <br />
            <br />

            <p>
              Hinweis:
              <br />
              Diese Email ist nur für den genannten Empfänger oder Vertreter
              bestimmt, falls Sie diese Email irrtümlicherweise erhalten haben,
              informieren Sie umgehend den Adressaten. Ein Missbrauch dieser
              Daten ist strafbar. Ich arbeite mit einem Virenschutzprogramm, für
              eventuelle Schäden übernehme ich keine Haftung. Öffnen Sie den
              Anhang nur, wenn ich in der Email darauf hinweise.
            </p>
            <br />
            <p>
              "Kontaktaufnahme per E-Mail: Ich weise darauf hin, dass die
              Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein
              lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
              nicht möglich. Sollten Sie über E-Mail Kontakt mit mir aufnehmen,
              erfolgt die Verarbeitung Ihrer Daten aufgrund Ihrer freiwillig
              erteilten Einwilligung gemäß Artikel 6 Abs 1 Satz 1 lit a DSGVO.
              Die Angabe Ihrer gültigen E-Mail Adresse ist erforderlich, um Ihre
              Anfrage beantworten zu können. Diese Daten und Ihre Nachricht
              werden nur zur Bearbeitung und Beantwortung Ihrer Anfrage
              gespeichert. Die Daten werden gelöscht, sobald sie nicht mehr
              erforderlich sind und keine gesetzliche Aufbewahrungspflicht
              besteht. Eine Weitergabe an Dritte erfolgt nicht. Bitte schreiben
              Sie keine vertraulichen Informationen, insbesondere
              Gesundheitsdaten in eine E-Mail, sondern nutzen Sie diese nur zur
              ersten Kontaktaufnahme."
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Impressum;
