import React from "react";


import image from "../pictures/web.png";

const Home = () => {
  return (
    <div className="home-container">
     
      <img src={image} alt="baustatik" className="home--image" ></img>
    </div>
  );
};

export default Home;
