import React from "react";
import contenfulConfig from "../constants/contentful";
import * as contentful from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default class Tätigkeitsbereiche extends React.Component {
  constructor() {
    super();
    this.state = {
      articles: []
    };
    this.client = contentful.createClient({
      space: contenfulConfig.space,
      accessToken: contenfulConfig.accessToken
    });
  }
  componentDidMount() {
    this.client
      .getEntries({
        content_type: "taetigkeitsbereiche",
        order: "fields.index"
      })
      .then(({ items }) => {
        this.setState({ articles: items });
      });
  }

  render() {
    return (
      <>
        <div className="container">
          {this.state.articles.map(({ fields }, i) => (
            <div key={`row_${i}`} className="rowRef">
              <h4> {fields.title}</h4>
              <div className="tableWhiteSpace"></div>
              <div className="column">
                {documentToReactComponents(fields.text)}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
