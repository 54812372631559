import React from "react";
import bg from "../pictures/bgzeichnung.gif";

const Footer = () => {
  return (
    <div className="footer">
      <img src={bg} alt="bg" className="bg" />
      <div className="footerRow">
        <div className="footerColumn">
          <p>Diplombauingenieur</p>
          <p>info@ibl-baustatik.de</p>
        </div>
        <div className="space"></div>
        <div className="footerColumn">
          <p>Karl-Georg Lambert</p>

          <p>0171 95 16 658</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
