import React from "react";
import up from "../pictures/up.png";
import down from "../pictures/down.png";

class Expand extends React.Component {
  state = {
    expanded: false
  };
  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    return (
      <div className="expand">
        <div className="title" onClick={this.toggleExpanded}>
          {this.state.expanded ? (
            <img className="updown" src={up} alt="up" />
          ) : (
            <img className="updown" src={down} alt="down" />
          )}
          {this.props.title}
        </div>

        {this.state.expanded ? (
          this.props.html ? (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: this.props.html }}
            />
          ) : null
        ) : null}
      </div>
    );
  }
}

export default Expand;
