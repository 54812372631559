import React from "react";
import contenfulConfig from '../constants/contentful'
import * as contentful from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Referenzen extends React.Component {
  constructor() {
    super();
    this.state = {
      articles: []
    };
    this.client = contentful.createClient({
      space: contenfulConfig.space,
      accessToken: contenfulConfig.accessToken
    });
  }
  componentDidMount() {
    this.client.getEntries({
      'content_type': 'post',
      order: "fields.index"
    }).then(({ items }) => {
      this.setState({ articles: items });
    });
  }

  render() {
    return (
      <>
        <div className="container">
          <div>
            {this.state.articles.map(({ fields }, i) => (
              <div key={`row_${i}`} className="rowRef">
                <div className="postFotoContainer">
                  <img
                    src={fields.picture.fields.file.url}
                    className="postFoto"
                    alt="referenz"
                    key={i}
                  />
                </div>
                <div className="tableWhiteSpace"></div>
                <div className="postText" key={i}>
                  <h3>{fields.title}</h3>
                  <br />
                  {documentToReactComponents(fields.text)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
