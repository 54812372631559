import React from "react";
import Hintergrund from "../pictures/Hintergrund.png";
import logo from "../pictures/logo.gif";
import bg from "../pictures/bgzeichnung.gif";
import { Link } from "react-router-dom";
import Footer from "./Footer.js";
import { Route, Redirect, Switch } from "react-router-dom";
import Tätigkeitsbereiche from "./Tätigkeitsbereiche";
import Impressum from "./Impressum";
import Referenzen from "./Referenzen";
import Kontakt from "./Kontakt";
import Home from "./Home";

class Container extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false
    };
  }
  handleClick = () => {
    this.setState({ active: !this.state.active });
  };
  handleNav = () => {
    this.setState({ active: false });
  };
  render() {
    console.log(window.location.pathname);
    return (
      <>
        <img src={bg} alt="bg" className="bg" />
        <div className="Logo">
          <Link to="/" onClick={this.handleNav}>
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <div className="burger" onClick={this.handleClick}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
        <nav>
          <div className="menu">
            <ul className={this.state.active ? "nav-active" : "nav-links"}>
              <li>
                <Link to="/taetigkeitsbereiche" onClick={this.handleNav}>
                  Tätigkeitsbereiche
                </Link>
              </li>
              <li>
                <Link to="/referenzen" onClick={this.handleNav}>
                  Referenzen
                </Link>
              </li>
              <li>
                <Link to="/kontakt" onClick={this.handleNav}>
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/impressum" onClick={this.handleNav}>
                  Impressum
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="containerHintergrund">
          {window.location.pathname === "" ||
          window.location.pathname === "/" ? null : (
            <img src={Hintergrund} className="hintergrund" alt="hintergrund" />
          )}
        </div>

        <Switch>
          {/* <Redirect exact from="/" to="/home" /> */}
          <Route exact path="/" component={Home} />
          <Route path="/taetigkeitsbereiche" component={Tätigkeitsbereiche} />
          <Route path="/referenzen" component={Referenzen} />
          <Route path="/kontakt" component={Kontakt} />
          <Route path="/impressum" component={Impressum} />
        </Switch>

        <Footer />
      </>
    );
  }
}

export default Container;
