import React from "react";

const Kontakt = () => {
  return (
    <>
      <div className="container">
        <div className="rowRef">
          <h4> Mail </h4>
          <div className="tableWhiteSpace"></div>
          <div className="column">
            <a className="google" href="mailto: info@ibl-baustatik.de">
              <p>info@ibl-baustatik.de</p>
            </a>
          </div>
        </div>
        <div className="rowRef">
          <h4> Anschrift</h4>
          <div className="tableWhiteSpace"></div>
          <div className="column">
            <p>IBL - Ingenieurbüro für Baustatik</p>
            <p>Dipl.-Ing. Karl-Georg Lambert</p>
            <br />

            <p>Ilseplatz 2</p>
            <p>66123 Saarbrücken</p>
            <a
              className="google"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.de/maps/place/Ilsepl.+2,+66123+Saarbr%C3%BCcken/@49.2415882,7.0134218,17z/data=!3m1!4b1!4m5!3m4!1s0x4795b69980f7ea83:0x3eef6c9c817e5c91!8m2!3d49.2415847!4d7.0156105"
            >
              Link: Google Maps
            </a>
            <br />
            <br />
            <p>Am Dichtberg 8</p>
            <p>66440 Blieskastel</p>
            <a
              className="google"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.de/maps/place/Am+Dichtberg+8,+66440+Blieskastel/@49.2432057,7.2736597,18.32z/data=!4m5!3m4!1s0x4795ce24a1caf13d:0x5827c4566d3e7156!8m2!3d49.2433351!4d7.2746419"
            >
              Link: Google Maps
            </a>
          </div>
        </div>

        <div className="rowRef">
          <h4> Fon </h4>
          <div className="tableWhiteSpace"></div>
          <div className="column">
            <p>Saarbrücken: 0681 688 97 170</p>
            <p>Blieskastel: 06842 708 54 05</p>
          </div>
        </div>

        <div className="rowRef">
          <h4> Mobil </h4>
          <div className="tableWhiteSpace"></div>
          <div className="column">
            <p>0171 95 16 658</p>
          </div>
        </div>

        <div className="rowRef">
          <h4> Fax </h4>
          <div className="tableWhiteSpace"></div>
          <div className="column">
            <p>0681 79 37 1009</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
